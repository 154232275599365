.dialog-title {
  //styleName: Card - Header Text;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  align-self: center;
}

.dialog__footer-container {
  background-color: #f8f9fa;
  max-width: 100%;
  border-top: 1px solid #e4e7eb;
  padding-block: 14px;
  padding-inline: 50px;
  margin: auto;
  border-radius: 8px;
}

.dialog__footer-container-in-success {
  background-color: #f8f9fa;
  padding-block: 14px;
  padding-inline: 50px;
  margin: auto;
  margin-top: 10px
}

.dialog__button {
  width: 180px;
  max-width: 180px;
}

.button__cancel {
  align-items: flex-start;
}

.button__text {
  align-items: flex-end;
}

.dialog__button--cancel {
  background-color: var(--color-primary-button-red) !important;
  border-color: var(--color-primary-button-red) !important;

}
.dialog__button--cancel:hover {
  background-color: var(--color-primary-button-red-hover) !important;
  border-color: var(--color-primary-button-red-hover) !important;
}

.project-details__container {
  padding-inline: 50px;
  padding-top: 30px;
  margin: auto;
  flex-wrap: wrap;
}

.dialog-report-error {
  height: 15px;
  color: #dc3545;
}

.modal__send-report__loader{
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}