.answers-grid-component__container {
  padding-inline: 20px;
  background-color: white;
}

.category-container__border {
  border: 1px solid #dee2e6;
}

.category-name {
  font-weight: bold;
}

.questions-container {
  display: grid;
  grid-template-columns: minmax(20%, 80%) 1fr ;
}

.benchmarksId-grid__container {
  display: grid;
  grid-template-columns: minmax(20%, 80%) 1fr ;
}

.question-grid__container {
  border: 1px solid rgb(222, 226, 230);
  padding-block: 15px;
  padding-inline: 18px;
}

.category-name__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0d6efd14;
  padding-block: 5px;
  padding-right: 5px;
  padding-left: 20px;
}

.category-name-text {
  color: #0d6efd;
  font-weight: 500;
  margin-left: 10px
}

.average-label {
  transform: rotate(-90deg) ;
  font-size: 10px;
  width: 20px; 
  height: auto; 
  color: #212529;
  margin-top: 23px;
}

.question-text__container {
  align-items: center;
  align-content: center;

}

.question-text {
  text-align: left;
}


.questionId {
  border-radius: 50%;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  max-height: 28px;
  max-width: 28px;
  font-size: 15px;
}

.questionId-question-text__container {
  display: flex;
  align-items: center;
  height: 100%;
}

.answers-container {
  display: flex;
  justify-content: space-between;
}

.answer-content {
    border: 1px solid rgb(222, 226, 230);
    width: 100%;
    height: 100%;
}

.answer {
  width: 50px;
  color: #0d6efd;
  font-weight: 500;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.average {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #212529;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-block: 2%;
  width: 50px;

}

.average-grid__container {
  display: grid;
  // grid-template-columns: minmax(20%, 80%) 1fr ;
  // margin: auto;
}

.average-background {
  background-color: #0d6efd14;

}
.benchmarkId-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-weight:500;
  color: black;
  width: 50px;
  margin: auto;
font-size: 12px;
  
}

.benchmarkId-grid__container {
   border: 1px solid #DEE2E6; 
   width: 100%
}

.benchmarkId-grid-container {
  display: flex;
  justify-content: space-between;
}

.grid-benchmarkid {
  display: flex;
  justify-content: space-between;
}
