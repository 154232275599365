.reports-evaluation__div-container {
   width: 100%;
   height: 100vh;
   background-color: var( --color-bg-gray);
}

.reports-table__container-padding {
   padding-inline: var(--padding-horizontal-primary);
   border-left: 1px solid #DEE2E6;
   padding-top: 16px;
}

