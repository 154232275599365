.div__container {
    background-color: #F8F8F8;
    height: 100vh;
}

.div__principal__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.p__text {
    text-align: left;
    padding-left: 25px;
    color: #0D6EFD;
}

.p__text__error {
    text-align: left;
    padding-left: 25px;
    color: #F1202B
}

.p__headerButtons {
    background-color: #F8F8F8;
    height: 100vh
}

.div__benchmarksTable {
    padding-left: 25px;
    padding-right: 25px
}