.component__container {
  background-color: #0d6efd14;
  border: 1px solid #e4e7eb;
  padding-inline: var(--padding-horizontal-primary);
  padding-block: 7px;
  max-width: 172px;
  z-index: 1;
  position: absolute;
  top:  0px ;
  left: 0px;
}

.map__container {
  display: flex;
  margin-block: 15px;
}

.rectangle {
  width: 52px;
  height: 9px;
  border-radius: 100px;
  margin-top: 5px;
}

.date-column {
  margin-right: 20px
}

