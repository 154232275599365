.header-button__container {
  display: flex;
  padding-top: 14px;
  padding-bottom: 10px;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  padding-inline: var(--padding-horizontal-primary);
  border-bottom: 1px solid #e4e7eb;
}

.final__test__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-titles);
}

.div__container__buttons {
  display: flex;
  gap: 18px;
}

.create__benchmark__button {
  border-radius: 4px;
}

.report__button {
  width: 211px;
}

.report__button:disabled {
  width: 211px;
  background-color: var(--color-outline-button-disable);
  border-color: var(--color-border-outline-button-disable);
  color: var(--color-text-button-disable);
}

$maxWidth: 800px;

@media (max-width: $maxWidth) {
  .create__benchmark__button,
  .report__button,
  .report__button:disabled {
    width: 150px;
  }
  .button-text-responsive {
    font-size: 85%
  }
  .final__test__text {
    font-size: 17px;
  }
}
