.login {
  background-color: var(--color-bg-gray);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login__card {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04);
  max-height: 100vh;
  max-width: 100vw;
  width: 892px;
  height: 660px;
}

.login-logo__container {
  max-width: 236px;
  width: 100%;
  overflow: hidden;
}

.login__card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login__title {
  font-weight: 300;
  font-size: 42px;
  line-height: 50.4px;
  margin: 32px 20px 55px;
}

.login__form-container {
  width: 90%;
  max-width: 400px;
}
