.info-tag__container {
  display: flex;
  gap: 8px;
  padding-inline: 20px;
  padding-block: 10px;
  gap: 8px;
  border-radius: 4px;
  background-color:var(--background-color-lightblue);
}

.info-tag__label {
  //styleName: Base Text/Base Text - Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.info-tag__value {
  //styleName: Base Text - Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-primary-button);
}
