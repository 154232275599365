.form__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 792px;
  max-width: 90%;
  padding-block: 30px;
}

.form__label {
  font-size: 16px;
  padding-bottom: 6px;
  font-weight: 400;
}

.form__input {
  margin-bottom: 16px !important;
}

.form__privacy-link {
  text-decoration: none;
  margin-inline-start: 29px;
  padding-block: 9px;
  padding-inline: 17px;
  color: var(--color-primary-button);
}

.form__privacy-link-icon {
  margin-inline-end: 6px;
}

.form__privacy-header-container {
  height: 42px;
  display: flex;
  align-items: center;
}

.form__privacy-text {
  //styleName: Base Text/Base Text;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #343a40;
  margin-block: 0 8px;
}

.form__dot-error__container {
  display: flex;
  align-items: center;
}

.form__dot-error {
  position: absolute;
  margin-bottom: 19px;
  margin-left: 2px;
}

.form__privacy-checkbox {
  display: flex;
  direction: row;
  align-items: center;
}

.form__privacy-label {
  margin-left: 8px;
}

.form__label-container {
  display: flex;
  align-items: center;
}
.error-message {
  overflow: hidden;
  max-height: 30px;
}

.error-message-project-name {
  overflow: hidden;
  max-height: 15px;
  // margin: 0%;
  margin-bottom: 10px;
margin-left: 5px ;
}

.error-message-form__container {
  height: 15px;
}
