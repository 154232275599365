.questionnaire-overview__header-container {
  width: 100%;
}

.questionnaire-overview__table-container {
  width: 100%;
  padding-inline: var(--padding-horizontal-primary);
  padding-block: 20px;
  background-color: var(--color-bg-gray);
  height: 100%;
}

.questionnaire-overview-table__header {
  display: none !important;
  border: none !important;
}
