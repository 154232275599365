.dashboard {
  background-color: var(--color-bg-gray);
  min-height: inherit;
  width: 100vw;
  padding-inline: 20px;
}

.dashboard__loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard__disclaimer {
  //styleName: Base Text/Base Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 100%;
  margin: 0px;
  margin-top: 70px;
}

.jobcode_container {

  margin-top: 20px;
  
}



.jobcode_title {
  font-size: 15px;
  font-weight: bold;
  // border-top: 2px solid white;
  // line-height: 24px;
  margin: 0px;
  text-align: left;
}
.jobcode_code {
  font-size: 15px;
  font-weight: 400;
  // line-height: 24px;
  margin: 0px;
  text-align: left;
  margin-bottom: 5px;
}
