.benchmark-modal-container {
  max-width: 70%;
  margin: auto;
  align-items: center;
}

.benchmark-modal-detail {
  text-align: right;
  font-weight: 500;

}

.incomplete-benchmark__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
}

.benchmark-modal-title {
  font-weight: 700;
  text-align: left;
}

.benchmark-modal-item {
  display: flex;
  justify-content: space-between;
  padding-inline: 15px;
  padding-block: 3px;

}

.incomplete-benchmark-modal__container {
  margin: auto;
  padding-block: 40px;
  padding-inline: 50px;
}


.discard-and-create__button {
  margin:auto; 
  align-items: center;
}
