@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --color-bg-gray: #f8f9fa;

  --color-primary-button: #0d6efd;
  --color-primary-button-hover: rgb(7, 82, 194);
  --color-bg-header-table: #e9ecef;
  --color-primary-button-red: #dc3545;
  --color-primary-button-red-hover: #a31f2c;
  --color-separator: #e4e7eb;
  --color-border-outline-button-disable: #E4E7EB;
  --color-outline-button-disable: #F8F8F8;
  --color-text-button-disable: #495057;
  --color-titles: #212529;

  --background-color-lightblue: #0d6efd14;

  --header-height: 101px;
  --dialog-width: 892px;

  --padding-horizontal-primary: 20px;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  color: var(--color-titles);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  min-height: calc(100vh - var(--header-height));
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
