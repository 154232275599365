.answer-option__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-width: 0;
}

.answer-option__button {
  border-radius: 100% !important;
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 120px;
  font-size: 56px !important;
  font-weight: 300;
  line-height: 67.2px !important;
  background-color: var(--background-color-lightblue) !important;

  // background-color: pink;
  border: 2px solid var(--color-primary-button) !important;
}
.answer-option__button:hover {
  background-color: var(--color-primary-button) !important;
}

.answer-option__button--selected {
  background-color: var(--color-primary-button) !important;
  color: white !important;
}
.answer-option__button--selected:hover {
  color: white !important;
}

.answer-option__description {
  //styleName: Base Text/Base Text - Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
}
