.children-dialog__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr ;
  width: 80%;
  column-gap: 70px;
  row-gap: 16px;
}

.children-dialog__input-container {
  padding-top: 70px;
  padding-bottom: 80px;
}

.children-dialog__label {
  font-weight: 700;
  color: #212529;
  margin-left: 2px;
}

.children-dialog__input {
    border-radius: 4px;
    border: 1px solid #CED4DA
}
