.dialog-title {
  //styleName: Card - Header Text;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  align-self: center;
}

.dialog__form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dialog__footer-container {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  width: calc(var(--dialog-width) - 100);
  max-width: 90%;
}

.dialog__button {
  width: 180px;
  max-width: 180px;
}

.dialog__button--cancel {
  background-color: var(--color-primary-button-red) !important;
  border-color: var(--color-primary-button-red) !important;
}
.dialog__button--cancel:hover {
  background-color: var(--color-primary-button-red-hover) !important;
  border-color: var(--color-primary-button-red-hover) !important;
}
