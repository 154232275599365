.login__login-failed-error-container {
  padding-bottom: 16px;
}

.login-form__label {
  font-size: 16px;
  padding-bottom: 12px;
  font-weight: 400;
}

.login-form__input {
  max-width: 400px;
  height: 42px;
}

.login-form__button {
  height: 42px;
  width: 100%;
  max-width: 207px;
  margin-top: 16px;
}