.chart-div__container {
  border: 1px solid #e4e7eb;
  position: relative;
}

.chart__div-content {
  background-color: white;
}

.chart__text {
  padding-top: 50px;
  padding-bottom: 20px;
  width: 700px;
  margin: auto;
}

.chart-loader {
 padding-top: 50%;
}
