.question-details__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;
  padding-right: 18px;
  padding-block: 18px;
  align-items: center;
  border: 1px solid #e4e7eb;
  justify-content: space-between;
}

// Question order rounded number
.question-details__order-container {
  width: 24px;
  height: 24px;
}
.question-details__order-container {
  width: 24px;
  height: 24px;
  position: relative;
}
.question-details__order-number {
  color: var(--color-primary-button);
  position: relative;
  width: 24px;
  top: 3px;
}
.question-details__order-number::before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100%;
  border: 1px solid var(--color-primary-button);
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Question text
.question-details__question {
  //styleName: Base Text/Base Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-block: 0px;
  margin-inline-start: 26px;
  width: 100%;
}

.question-details__answer {
  //styleName: Card - Header Text;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-inline-start: 35px;
  margin-inline-end: 13px;
}

.question-details__edit-button {
  margin-inline-start: 18px;
}

@media screen and (max-width: 768px) {
  .question-details__question {
    //styleName: Base Text/Base Text;
    font-size: 14px;
    margin-inline-start: 18px;
  }

  .question-details__answer {
    //styleName: Card - Header Text;
    font-size: 18px;
    margin-inline-start: 12px;
  }

  .question-details__edit-button {
    margin-inline-start: 0px;
  }
}
