.questionnaire-question__container {
  display: flex;
  flex-direction: column;
  padding-block: 60px;
  align-items: center;
  padding-inline: var(--padding-horizontal-primary);
  width: 100%;
  background-color: var(--color-bg-gray);
}

.questionnaire-question__question-beggining {
  //styleName: Display/Display 4;
  font-size: 42px;
  font-weight: 300;
  line-height: 50.4px;
  margin: 0px;
}

.questionnaire-question__question-body {
  //styleName: Base Text/Base Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 780px;
  min-height: 96px;
  margin-top: 22px;
  margin-bottom: 40px;
}
