.create-report__container {
  background-color: var(--color-bg-gray);
  width: 100%;
  height: 100vh;
}

.chart__container {
  width: 100%;
  padding-inline: var(--padding-horizontal-primary);
  padding-top: 20px;
  margin: auto;
}


