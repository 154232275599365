.header-action-bar__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: var(--padding-horizontal-primary);
  padding-block: 12px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--color-separator);
  background-color: white;
}

.header-action-bar__title {
  //styleName: Card - Header Text;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 20px;
  color: #212529;
}

.header-action-bar__container span {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .header-action-bar__title {
    font-size: 16px;
  }

  .info-tag__container {
    margin-inline: 4px;
    padding-inline: 10px;
  }

  .info-tag__label {
    font-size: 14px;
  }
}
