.header-category__div-container {
    width: 100%;
    background-color: var(--background-color-lightblue);

    padding-inline: var(--padding-horizontal-primary);
    padding-top: 24px;
    padding-bottom: 22px;
    border: 1px solid #E4E7EB ;
    margin: auto;
}

.header-category__div-content {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.header-category__div-right {
    margin-left: auto;
}

.header-category__div-id {
    font-weight: 500;
    margin-right: 12px;
    color: #343A40;
}

.header-category__div-name {
    color: #0d6efd;
    font-weight: 500;

}