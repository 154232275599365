.questionnaire-footer__container {
  display: flex;
  justify-content: space-between;
  padding-inline: var(--padding-horizontal-primary);
  padding-block: 12px;
  border-top: 1px solid var(--color-separator);
  width: 100%;
  gap: 20px;
}

.questionnaire-footer__button {
  width: 366px;
  max-width: 45%;
}
