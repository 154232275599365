.survey-buttons-container {
  padding-inline: 20px;
}

.survey-buttons__container {
  padding-inline: var(--padding-horizontal-primary);
  background: white;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  display: flex;
  padding-block: 2px;
}

.survey-buttons-container__content {
  overflow: auto;
  padding-right: 20px;
  align-items: center;
  display: inline-grid;
  grid-template-columns: repeat("auto-fit,1fr");
  grid-gap: 6px;
  grid-auto-flow: column;
}

.survey__button {
  padding: 8px, 12px, 8px, 12px;
  border: 1px solid #dee2e6;
}

.survey-buttons-title {
  align-content: start;
  font-weight: 700;
  color: #212529;
  font-size: 14.5px;
  min-width: 15%;
  text-align: start
}

.survey-buttons-button {
  border-radius: 8px;
}
