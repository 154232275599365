.item-detail__container {
  padding-inline: var(--padding-horizontal-primary);
  padding-block-start: 15px;
  padding-block-end: 19px;
  padding-top: 15px;
  padding-bottom: 19px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  background: white;
  border-bottom: 1px solid #e4e7eb;
  max-width: 100%;
}

$maxWidth: 800px;
@media screen and (max-width: 768px) {
  .item__container {
    width: 130px !important;
    text-align: left;
    margin-right: 20px;
  }
  .h6__style {
    font-size: 14px !important;
}
  .h6__style2 {
    font-size: 14px !important;
}
}

.item__container {
  text-align: left;
}

.h6__style {
  display: flex;
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  color: var(--color-titles);
  align-items: flex-start;
  height: 24px;
}

.h6__style2 {
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.date-picker__input {
  border-radius: 4px;
}

.date-input__container {
  width: 200px;
  display: flex;
  flex-direction: column;
}

// ------------- Job Code styles for Footer ------------- \\

.job-code__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-block: 10px;
}

.h6__style__job-code,
.h6__style2__job-code-value {
  font-size: 16px;
  margin: 0;
  text-align: center;
}

.h6__style2__job-code-value {
  font-weight: 400;
}
