.questionnaire-recap__container {
  display: flex;
  flex-direction: column;
  padding-block: 60px;
  align-items: center;
  background-color: var(--color-bg-gray);
  width: 100%;
}

.questionnaire-recap__title {
  //styleName: Display/Display 4;
  font-size: 42px;
  font-weight: 300;
  line-height: 50.4px;
  margin-top: 0px;
  margin-bottom: 30px;
}
