.header {
  height: calc(var(--header-height) - 1px);
  // width: 100vw;
  background-color: white;
  border-bottom: solid 1px rgba(228, 231, 235, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: var(--padding-horizontal-primary);
}

.header__logo {
  max-width: 166px;
}

.header__text {
  //styleName: Card - Header Text;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0 0 0 16px;
}

.header__button {
  width: 100%;
  max-width: 211px;
}

.export-text {
  margin-right: 14px;
  font-weight: 500;
  font-size: 17px;
}
