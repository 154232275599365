$kendo-color-primary: #0d6efd;

@import '~@progress/kendo-theme-bootstrap/dist/all.scss';

/**********************************************/
/********** Kendo classes customized **********/
/**********************************************/

/*********** PRIMARY BUTTON ******************/
.k-button-solid-primary:hover {
  background-color: var(--color-primary-button-hover);
  border-color: var(--color-primary-button-hover);
}
/*********** SECONDARY BUTTON ******************/
.k-button-solid-secondary:hover {
  background-color: var(--color-primary-button-hover);
  border-color: var(--color-primary-button-hover);
}

/**************** TABLE *******************/
.k-grid {
  border: none;
}
/************ HEADER TABLE *******************/
.k-grid .k-grid-header .k-table-th {
  background-color: var(--color-bg-header-table);
}
.k-grid .k-grid-header {
  border-bottom-width: 0;
}

/************ DIALOG CONTAINER *******************/
.k-dialog {
  width: var(--dialog-width);
  max-width: 90%;
}
/************ HEADER DIALOG *******************/
.k-window-titlebar {
  padding-top: 24px;
  padding-bottom: 22px;
  padding-inline: 30px;
  background-color: var(--color-bg-gray);
}
.k-window-title {
  font-size: 1rem;
}
/************ BODY DIALOG *******************/
.k-window-content {
  padding: 0;
}
/************ FOOTER DIALOG *******************/
.k-dialog-actions {
  padding-block: 0;
  padding-inline: 0px;
  background-color: var(--color-bg-gray);
  justify-content: center;
}

/************* CHECKBOX (create proyect privacy terms) *******************/
.k-checkbox-label {
  margin-inline-start: 8px !important;
}

/***************** STEPPER **********************/
/************* STEPPER LABELS *******************/
.k-stepper .k-step-label .k-step-text {
  white-space: normal;
  text-overflow: clip;
  overflow: visible;
}
@media screen and (max-width: 1024px) {
  .k-stepper .k-step-label .k-step-text {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 768px) {
  .k-stepper .k-step-label .k-step-text {
    font-size: 0.6em;
  }
}
.k-stepper .k-step-indicator::after {
  display: none !important;
}
/************* STEPPER DISABLED *******************/
.k-stepper .k-step.k-step-disabled .k-step-indicator,
.k-stepper .k-step.k-disabled .k-step-indicator,
.k-stepper .k-step:disabled .k-step-indicator {
  color: white;
}
.k-stepper .k-step-indicator {
  background-color: #6c757d;
  border-color: #6c757d;
}
.k-stepper .k-step.k-step-disabled .k-step-label,
.k-stepper .k-step.k-disabled .k-step-label,
.k-stepper .k-step:disabled .k-step-label {
  color: #212529;
}
