.questionnaire-report__container {
   width: 100%;
}

.questionnaire-report__chart-section-container--external {
   background-color: var(--color-bg-gray);
   padding-top: var(--padding-horizontal-primary);
   padding-inline: var(--padding-horizontal-primary);
}

.questionnaire-report__chart-section--container {
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.questionnaire-report__chart--container {
   width: 100%;
}

.questionnaire-report__chart-section--container p {
   margin-top: 0;
   margin-bottom: 30px;
   max-width: 780px;
}