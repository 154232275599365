.custom-switch__container {
  margin-bottom: 16px !important;
}

.custom-switch__button {
  width: 180px;
  max-width: 50%;
  height: 42px;
}

.custom-switch__button--left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.custom-switch__button--right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}