.title {
  //styleName: Card - Header Text;
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0px;
text-align: left;
}

.card-list__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row: 246px;
  gap: 20px;
}