.privacy-notice__container {
  max-width: 90%;
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-block-end: 30px;
}

.privacy-notice__title {
  width: 100%;
  margin-block: 30px;
}

.privacy-notice__container h3 {
  margin: 0px;
}

.privacy-notice__container p {
  text-align: start;
}