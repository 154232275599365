.card-header__container {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-style: none !important;
  background-color: white !important;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style-type: none;
  text-align: start;
}

.card__button {
  height: 54px;
  width: inherit;
  background-color: var(--color-primary-button);
  color: white;
  padding: 0;
  justify-content: center !important;
}

.card__button button {
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--color-primary-button);
  color: white;
  padding: 0;
}
